import React from 'react'
import { Col, Row, List} from 'antd';
import botellaJagger from '../../resources/img/botellaJagger.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
// const { Panel } = Collapse


const StoreList = ( props ) => {

    const elementsList = [
        {
            descripcion: 'Monthly Meeting',
            dato: props.detailVisit.storemgmt_monthtlyMeeting > 0 ? <FontAwesomeIcon icon={(faCircleCheck)} style={{color: "#36c953",}} size="xl"/> :<FontAwesomeIcon icon={faCircleXmark} style={{color: "#ce3b3b",}} size="xl"/>
            // dato: props.detailVisit.storemgmt_monthtlyMeeting > 0 ? 'Yes' : 'No'
        },
        {
            title: 'Task Agenda',
            descripcion: ''
        }
        // ,
        // {
        //     descripcion: 'Arranged Point for Next Meeting 1',
        //     dato: '-'
        // },
        // {
        //     descripcion: 'Arranged Point for Next Meeting 2',
        //     dato: '-'
        // },
        // {
        //     descripcion: 'Arranged Point for Next Meeting 3',
        //     dato: '-'
        // }
    
    ];

    for( let arrangedMeeting of props.detailVisit.tarea.taskAgendaList ){
        elementsList.push(
            {
                descripcion: arrangedMeeting.pointnextmeeting,
                dato: ''
            }
        )
    }

    elementsList.push(
        {
            descripcion: 'Meeting Length',
            dato: props.detailVisit.storemgmt_meetnglenght < 0 ? 'No Meeting Length' :`${props.detailVisit.storemgmt_meetnglenght} minutes`
        }
    )

    elementsList.push(
        {
            title: 'Actions:',
            descripcion: props.detailVisit.storemgmt_actions === "" ? 'No actions': props.detailVisit.storemgmt_actions,
    
        }
    )

    elementsList.push(
        {
            title: 'Next Meeting:',
            descripcion: props.detailVisit.storemgmt_nextmeetng === 0 ? 'No Next Meeting':  props.detailVisit.storemgmt_nextmeetngdate
            // '03/03/2023 23:03:04' 
            ,
    
        }
    )

    return (
        <div>
            <Row>
                <Col span={14}>
                    <List
                        size="small"
                        bordered
                        dataSource={ elementsList }
                        renderItem={(item) =>
                            <List.Item>
                                <p>
                                    <strong>{item.title} </strong>
                                </p>
                                {item.descripcion} <span>{item.dato}</span>
                            </List.Item>}
                    />
                </Col>
                <Col span={10}>
                    <div className='imagenList'>
                        <img src={botellaJagger} />
                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default StoreList