import { Col, Button, Row, Select, DatePicker, Space, TreeSelect } from 'antd';
import React, { useCallback, useContext, useEffect, useRef, useState, } from 'react'
import iconoFiltro from './resources/img/iconoFiltrar.png';
import './FilterComponent.css';
import LogUserInformationContext from '../store/LogUser-context';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';


// All select


const { RangePicker } = DatePicker;


const FiltroComponentDashboard = (props) => {

    // All select

    dayjs.extend(customParseFormat);
    const userLogCtx = useContext(LogUserInformationContext);
    const [regionFilter, setRegionFilter] = useState([]);

    const [filtersValue, setFiltersValue] = useState({
        regions: [],
        countries: [],
        airports: [],
        bas: []
    });

    const [countryFilter, setCountryFilter] = useState([]);
    const [airportFilter, setAirportFilter] = useState([]);
    const [ambassadorFilter, setAmbassadorFilter] = useState([]);

    const [regionSelected, setRegionSelected] = useState([]);
    const [countrySelected, setCountrySelected] = useState([]);
    const [baSelected, setBaSelected] = useState([]);
    const [airportsSelected, setAirportsSelected] = useState([]);
    const [dateRangeSelected, setDateRangeSelected] = useState(['','']);

    const [regionFilterValid, setRegionFilterValid] = useState(true);
    const [rangeDateFilterValid, setRangeDateFilterValid] = useState(true);

    let dateArray = [];

    const fetchCatalogue = useCallback(
        async () => {
            try {
                // const userObject = await JSON.parse(localStorage.getItem('UserLogInf'));
                const tokenKey = localStorage.getItem('LogTokenAccess') ? localStorage.getItem('LogTokenAccess') : '';
                const catalogueFilters = await fetch(`${userLogCtx.domainUrlAPI}/controllers/visits/getCataloguesWeb`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': tokenKey
                    },

                });

                const regionFilterValue = [];


                const catalogueFiltersConverted = await catalogueFilters.json();
                // console.log("Catalogue filters" , catalogueFiltersConverted );
                for (let region of catalogueFiltersConverted.regions) {
                    regionFilterValue.push({
                        value: region.idregion,
                        title: region.region

                    })
                }



                setRegionFilter(regionFilterValue);
                setFiltersValue(
                    catalogueFiltersConverted
                );

            }
            catch (err) {
                console.log('Error', err);
            }
        }
        , [userLogCtx.domainUrlAPI])

    const onChangeRegion = (currentRegionsSelected) => {

        const countriesList = [];

        if (currentRegionsSelected.length > 0) {

            for (let country of filtersValue.countries) {
                if (currentRegionsSelected.includes(country.region.idregion)) {
                    countriesList.push({
                        value: country.idpais,
                        title: country.pais,
                    })
                }
            }
            // this.handleSelectAll()
        }

        setRegionSelected(currentRegionsSelected);
        setCountryFilter(countriesList);
        setAirportsSelected([]);
        setCountrySelected([]);
        setBaSelected([]);
        setAirportFilter([]);
        setAmbassadorFilter([]);


    }


    const allRegionIds = regionFilter.map(({value})=> value )

    const onChangeCountry = (currentCountriesSelected) => {

        const airportList = [];
        for (let airport of filtersValue.airports) {
            if (currentCountriesSelected.includes(airport.pais.idpais)) {
                airportList.push({
                    value: airport.idSucursal,
                    title: airport.direccion,
                })
            }
        }

        const basList = [];
        for (let ba of filtersValue.bas) {
            if (currentCountriesSelected.includes(ba.pais.idpais)) {
                basList.push({
                    value: ba.idUsuario,
                    title: ba.nombre,
                })
            }
        }


        setCountrySelected(currentCountriesSelected);
        setAirportFilter(airportList);
        setAmbassadorFilter(basList);
        setBaSelected([]);
        setAirportsSelected([]);
    }
    const allCountryIds = countryFilter.map(({value})=> value )


    const onChangeBA = (currentBasSelected) => {
        setBaSelected(currentBasSelected);
    }
    const allBasIds = ambassadorFilter.map(({value})=> value )

    const onChangeAirtports = (currentAirportsSelected) => {
        setAirportsSelected(currentAirportsSelected);
        //cambio temporal
        // setBaSelected([]);
    }
    const allAirportsIds = airportFilter.map(({value})=> value )


    const onChangeDate = (dayjs, dateString) => {
        // console.log(dateRangeSelected);
        setDateRangeSelected(dateString);
        console.log(dateString);
    }

    const onClickFilter = () => {
        console.log(dateRangeSelected[1]);
        if (regionSelected.length === 0
            // || airportsSelected.length === 0
        ) {
            setRegionFilterValid(false);
            return;
        }

        setRegionFilterValid(true);

        if (dateRangeSelected[0] === '' || dateRangeSelected[1] === '') {
            setRangeDateFilterValid(false);
            return;
        }

        setRangeDateFilterValid(true);
        // console.log("Region selected", regionSelected.toString());
        // console.log("Country selected", countrySelected.toString());
        // console.log("Ba selected", baSelected.toString());
        // console.log("Airports selected", airportsSelected.toString());
        // console.log("Initial Date", dateRangeSelected[0]);
        // console.log("End Date", dateRangeSelected[1]);
        props.onFilter(regionSelected.toString(), countrySelected.toString(), baSelected.toString(),
            airportsSelected.toString(),
            // airportsSelected.toString(),
            dateRangeSelected[0], dateRangeSelected[1] 
        )

    }

    useEffect(() => {
        fetchCatalogue()

    }, [fetchCatalogue]);


    var limitDate = new Date();
    let limitDateString = limitDate.getFullYear() + "-" + ((limitDate.getMonth() ) > 9 ? (limitDate.getMonth() ) : "0" + (limitDate.getMonth() )) + "-" + limitDate.getDate();

    return (
        <div className='contentFiltro'>
            <Row>
                <Col span={8}>

                    <p>
                        Regions:
                    </p>


                    <TreeSelect
                        allowClear={true}
                        placeholder="Search to Select"
                        treeCheckable={true}
                        showCheckedStrategy={TreeSelect.SHOW_CHILD}
                        style={{ width: "90%" }}
                        dropdownStyle={{ maxHeight: "300px" }}
                        onChange={ids => onChangeRegion(ids)}
                        value={regionSelected}
                        maxTagCount={2}
                        status={regionFilterValid ? '' : 'error'}
                        maxTagPlaceholder={omittedValues =>
                            `+ ${omittedValues.length} Regions ...`
                        }
                        treeData={[
                            {
                                title: (
                                    <React.Fragment>
                                        {regionSelected.length === regionFilter.length && (
                                            <span
                                                style={{
                                                    display: "inline-block",
                                                    color: "#ccc",
                                                    cursor: "not-allowed"
                                                }}
                                            >
                                                Select all
                                            </span>
                                        )}
                                        {regionSelected.length < regionFilter.length && (
                                            <span
                                                onClick={() => onChangeRegion(allRegionIds)}
                                                style={{
                                                    display: "inline-block",
                                                    color: "#286FBE",
                                                    cursor: "pointer"
                                                }}
                                            >
                                                Select all
                                            </span>
                                        )}
                                        &nbsp;&nbsp;&nbsp;
                                        {regionSelected.length === 0 && (
                                            <span
                                                style={{
                                                    display: "inline-block",
                                                    color: "#ccc",
                                                    cursor: "not-allowed"
                                                }}
                                            >
                                                Unselect all
                                            </span>
                                        )}
                                        {regionSelected.length > 0 && (
                                            <span
                                                onClick={() => onChangeRegion([])}
                                                style={{
                                                    display: "inline-block",
                                                    color: "#286FBE",
                                                    cursor: "pointer"
                                                }}
                                            >
                                                Unselect all
                                            </span>
                                        )}
                                    </React.Fragment>
                                ),
                                // value: "xxx",
                                disableCheckbox: true,
                                disabled: true
                            },
                            ...regionFilter
                        ]}
                    />

                    {/* <Select
                        showSearch
                        className='select-filter'
                        value={regionSelected}
                        onChange={onChangeRegion}

                        dropdownStyle={{ overflowX: 'scroll' }}
                        style={{
                            width: '90%',
                        }}
                        mode="multiple"
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        status={regionFilterValid ? '' : 'error'}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={
                            regionFilter
                        }
                    /> */}
                </Col>
                <Col span={8}>

                    <p>
                        Countries:
                    </p>
                    <TreeSelect
                        allowClear={true}
                        placeholder="Search to Select"
                        treeCheckable={true}
                        showCheckedStrategy={TreeSelect.SHOW_CHILD}
                        style={{ width: "90%" }}
                        dropdownStyle={{ maxHeight: "300px" }}
                        onChange={ids => onChangeCountry(ids)}
                        value={countrySelected}
                        maxTagCount={2}
                        maxTagPlaceholder={omittedValues =>
                            `+ ${omittedValues.length} Countries ...`
                        }
                        treeData={[
                            {
                                title: (
                                    <React.Fragment>
                                        {countrySelected.length === countryFilter.length && (
                                            <span
                                                style={{
                                                    display: "inline-block",
                                                    color: "#ccc",
                                                    cursor: "not-allowed"
                                                }}
                                            >
                                                Select all
                                            </span>
                                        )}
                                        {countrySelected.length < countryFilter.length && (
                                            <span
                                                onClick={() => onChangeCountry(allCountryIds)}
                                                style={{
                                                    display: "inline-block",
                                                    color: "#286FBE",
                                                    cursor: "pointer"
                                                }}
                                            >
                                                Select all
                                            </span>
                                        )}
                                        &nbsp;&nbsp;&nbsp;
                                        {countrySelected.length === 0 && (
                                            <span
                                                style={{
                                                    display: "inline-block",
                                                    color: "#ccc",
                                                    cursor: "not-allowed"
                                                }}
                                            >
                                                Unselect all
                                            </span>
                                        )}
                                        {countrySelected.length > 0 && (
                                            <span
                                                onClick={() => onChangeCountry([])}
                                                style={{
                                                    display: "inline-block",
                                                    color: "#286FBE",
                                                    cursor: "pointer"
                                                }}
                                            >
                                                Unselect all
                                            </span>
                                        )}
                                    </React.Fragment>
                                ),
                                // value: "xxx",
                                disableCheckbox: true,
                                disabled: true
                            },
                            ...countryFilter
                        ]}
                    />

                    {/* <Select
                        showSearch
                        className='select-filter'
                        value={countrySelected}
                        onChange={onChangeCountry}
                        style={{
                            width: '90%',
                        }}
                        mode="multiple"
                        status={regionFilterValid ? '' : "error"}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={
                            countryFilter

                        }
                    /> */}
                </Col>
                <Col span={8}>
                    <p>
                        B.A:
                    </p>
                    <TreeSelect
                        allowClear={true}
                        placeholder="Search to Select"
                        treeCheckable={true}
                        showCheckedStrategy={TreeSelect.SHOW_CHILD}
                        style={{ width: "90%" }}
                        dropdownStyle={{ maxHeight: "300px" }}
                        onChange={ids => onChangeBA(ids)}
                        value={baSelected}
                        maxTagCount={2}
                        maxTagPlaceholder={omittedValues =>
                            `+ ${omittedValues.length} B.A ...`
                        }
                        treeData={[
                            {
                                title: (
                                    <React.Fragment>
                                        {baSelected.length === ambassadorFilter.length && (
                                            <span
                                                style={{
                                                    display: "inline-block",
                                                    color: "#ccc",
                                                    cursor: "not-allowed"
                                                }}
                                            >
                                                Select all
                                            </span>
                                        )}
                                        {baSelected.length < ambassadorFilter.length && (
                                            <span
                                                onClick={() => onChangeBA(allBasIds)}
                                                style={{
                                                    display: "inline-block",
                                                    color: "#286FBE",
                                                    cursor: "pointer"
                                                }}
                                            >
                                                Select all
                                            </span>
                                        )}
                                        &nbsp;&nbsp;&nbsp;
                                        {countrySelected.length === 0 && (
                                            <span
                                                style={{
                                                    display: "inline-block",
                                                    color: "#ccc",
                                                    cursor: "not-allowed"
                                                }}
                                            >
                                                Unselect all
                                            </span>
                                        )}
                                        {countrySelected.length > 0 && (
                                            <span
                                                onClick={() => onChangeBA([])}
                                                style={{
                                                    display: "inline-block",
                                                    color: "#286FBE",
                                                    cursor: "pointer"
                                                }}
                                            >
                                                Unselect all
                                            </span>
                                        )}
                                    </React.Fragment>
                                ),
                                // value: "xxx",
                                disableCheckbox: true,
                                disabled: true
                            },
                            ...ambassadorFilter
                        ]}
                    />
                    {/* <Select
                        showSearch
                        value={baSelected}
                        className='select-filter'
                        onChange={onChangeBA}
                        popupMatchSelectWidth={false}
                        status={regionFilterValid ? '' : "error"}
                        ref={basRef}
                        style={{
                            width: '90%',
                        }}
                        allowClear
                        mode="multiple"
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={
                            ambassadorFilter

                        }
                    /> */}
                </Col>
                <Col span={8}>
                    <p>
                        Airport:
                    </p>
                    <TreeSelect
                        allowClear={true}
                        placeholder="Search to Select"
                        treeCheckable={true}
                        showCheckedStrategy={TreeSelect.SHOW_CHILD}
                        style={{ width: "90%" }}
                        dropdownStyle={{ maxHeight: "300px" }}
                        onChange={ids => onChangeAirtports(ids)}
                        value={airportsSelected}
                        maxTagCount={2}
                        maxTagPlaceholder={omittedValues =>
                            `+ ${omittedValues.length} Airports ...`
                        }
                        treeData={[
                            {
                                title: (
                                    <React.Fragment>
                                        {airportsSelected.length === airportFilter.length && (
                                            <span
                                                style={{
                                                    display: "inline-block",
                                                    color: "#ccc",
                                                    cursor: "not-allowed"
                                                }}
                                            >
                                                Select all
                                            </span>
                                        )}
                                        {airportsSelected.length < airportFilter.length && (
                                            <span
                                                onClick={() => onChangeAirtports(allAirportsIds)}
                                                style={{
                                                    display: "inline-block",
                                                    color: "#286FBE",
                                                    cursor: "pointer"
                                                }}
                                            >
                                                Select all
                                            </span>
                                        )}
                                        &nbsp;&nbsp;&nbsp;
                                        {airportsSelected.length === 0 && (
                                            <span
                                                style={{
                                                    display: "inline-block",
                                                    color: "#ccc",
                                                    cursor: "not-allowed"
                                                }}
                                            >
                                                Unselect all
                                            </span>
                                        )}
                                        {airportsSelected.length > 0 && (
                                            <span
                                                onClick={() => onChangeAirtports([])}
                                                style={{
                                                    display: "inline-block",
                                                    color: "#286FBE",
                                                    cursor: "pointer"
                                                }}
                                            >
                                                Unselect all
                                            </span>
                                        )}
                                    </React.Fragment>
                                ),
                                // value: "xxx",
                                disableCheckbox: true,
                                disabled: true
                            },
                            ...airportFilter
                        ]}
                    />
                    {/* <Select
                        onChange={onChangeAirtports}
                        value={airportsSelected}
                        className='select-filter'
                        popupMatchSelectWidth={false}
                        // dropdownStyle={{width: '1000px', overflowX:'auto', background: '#0f1d09', color: 'white'}}
                        showSearch
                        status={regionFilterValid ? '' : "error"}
                        style={{
                            width: '90%',
                        }}
                        mode="multiple"
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={
                            airportFilter

                        }
                    /> */}
                </Col>
                <Col span={8}>
                    <p>
                        Date:
                    </p>
                    <Space className='full' direction="vertical" size={12} >
                        <RangePicker
                            dateFormat="yyyy MMMM"
                            maxDate={dayjs(limitDateString, "YYYY-MM")}
                            // max={moment().format("YYYY-MM-DD")}
                            picker="month"
                            status={rangeDateFilterValid ? '' : "error"}
                            // onCalendarChange={onChangeDate}
                            onChange={onChangeDate}

                        />
                    </Space>
                </Col>
                <Col className='center' span={3}>
                    <p>
                        Filter:
                    </p>
                    <Button className='botonFiltro' type="" onClick={onClickFilter}>
                        Filter Data <img src={iconoFiltro} alt="Cars Selected" />
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default FiltroComponentDashboard