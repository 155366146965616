import React, { useState } from "react"
import './Modalstyle.css';
import './Modal.css';

/*const modalReducer = (state, action )=>{
    if( action.type === 'ADD'){
        const updatedTotalAmount = state.totalAmount + action.item.price * action.item.amount;

        const existingCartItemIndex = state.items.findIndex( 
            item => item.id === action.item.id
            );

        const existingCartItem = state.items[existingCartItemIndex];
        let updatedItems=[];
        if( existingCartItem ){
            const updatedItem ={
                ...existingCartItem,
                amount: existingCartItem.amount + action.item.amount
            }
            updatedItems =[...state.items];
            updatedItems[existingCartItemIndex] = updatedItem;
    
        }
        else{
            // updatedItem = {...action.item}
            updatedItems = state.items.concat(action.item);

        }

        return {
            items: updatedItems,
            totalAmount: updatedTotalAmount
        }
    }
    if( action.type === 'REMOVE'){
        const existingCartItemIndex = state.items.findIndex( 
            item => item.id === action.id
            );

        const existingItem = state.items[existingCartItemIndex]
        const updatedTotalAmount = state.totalAmount - existingItem.price;
        let updatedItems;
        if(existingItem.amount === 1){
            updatedItems = state.items.filter(item => item.id !== action.id);

        }
        else{
            const updatedItem = {...existingItem, amount: existingItem.amount - 1};
            updatedItems = [...state.items];
            updatedItems[existingCartItemIndex] = updatedItem;
        }
        return {
            items: updatedItems,
            totalAmount: updatedTotalAmount
        }

    }
    return defaulCartState;

}*/

const Backdrop = props => {
    return <div className="background-modal" onClick={props.onCloseModal} />
}

const Modal = props =>{

    const [tabSelected,setTabSelected] = useState(1);
    // const [showSales, setShowSales] = useState(true);
    // const [showShelf, setShowShelf] = useState(false);
    // const [showSecondary, setShowSecondary] = useState(false);
    // const [showStock, setShowStock] = useState(false);
    // const [showStore, setShowStore] = useState(false);
    // const [showSupplementary, setShowSupplementary] = useState(false);

    const salesSelectedhandler = () => {
        setTabSelected(1)
    }

    const shelfSelectedhandler = () => {
        setTabSelected(2)
    }

    const secondarySelectedhandler = () => {
        setTabSelected(3)
    }
    const stockSelectedhandler = () => {
        setTabSelected(4)
    }
    const storeSelectedhandler = () => {
        setTabSelected(5)
    }
    const supplementarySelectedhandler = () => {
        setTabSelected(6)
    }

    var salesSelected = true;
    var shelfSelected = false;
    var secondarySelected = false;
    var stockSelected = false;
    var storeSelected = false;
    var supplementartSelected = false;

    if(tabSelected === 1){
        salesSelected = true;
        shelfSelected = false;
        secondarySelected = false;
        stockSelected = false;
        storeSelected = false;
        supplementartSelected = false;
    }

    if(tabSelected===2){
        salesSelected = false;
        shelfSelected = true;
        secondarySelected = false;
        stockSelected = false;
        storeSelected = false;
        supplementartSelected = false;
    }

    if(tabSelected===3){
        salesSelected = false;
        shelfSelected = false;
        secondarySelected = true;
        stockSelected = false;
        storeSelected = false;
        supplementartSelected = false;
    }

    if(tabSelected===4){
        salesSelected = false;
        shelfSelected = false;
        secondarySelected = false;
        stockSelected = true;
        storeSelected = false;
        supplementartSelected = false;
    }

    if(tabSelected===5){
        salesSelected = false;
        shelfSelected = false;
        secondarySelected = false;
        stockSelected = false;
        storeSelected = true;
        supplementartSelected = false;
    }

    if(tabSelected===6){
        salesSelected = false;
        shelfSelected = false;
        secondarySelected = false;
        stockSelected = false;
        storeSelected = false;
        supplementartSelected = true;
    }

    return (
        <React.Fragment>
        <div className="background-modal" onClick={props.onCloseModal} />
        <div className="my-modal">
        <div className="panel panel-minimalistic" id="detailreportmodal" style={{zIndex:1000}}>

            <div className="panel-heading" role="tab" id="headingOne" data-toggle="collapse" href="#collapseOne"
                aria-expanded="true" aria-controls="collapseOne">

                <div className="row">
                    {/* <div className="col-lg-6"> */}

                        <label><span className="span-detail">Store: </span>
                            Store name
                        </label>
                    {/* </div> */}
                </div>
                {/* <div className="col-lg-6"> */}
                <label><span className="span-detail">Date: </span>  2023/03/23  </label>
                {/* </div> */}


            </div>


     <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
        <div className="panel-body">
            <ul className="nav nav-tabs" role="tablist">

                <li role="presentation" 
                // className="active" 
                onClick={salesSelectedhandler}><a >Sales Target</a></li>

                <li role="presentation" onClick={shelfSelectedhandler}><a href="#/"  data-toggle="tab" >Shelf</a></li>

                <li role="presentation" onClick={secondarySelectedhandler}><a href="#/" data-toggle="tab" >Secondary</a></li>

                <li role="presentation" onClick={stockSelectedhandler}><a href="#/"  data-toggle="tab">Stock</a></li>

                <li role="presentation" onClick={storeSelectedhandler}><a href="#/"  data-toggle="tab" >Store</a></li>

                <li role="presentation" onClick={supplementarySelectedhandler}><a href="#/" data-toggle="tab" >Supplementary</a></li>

                {/* <li role="presentation"><a href="#counter" aria-controls="counter" role="tab" data-toggle="tab">Counter</a></li> */}
            </ul>

            <div className="tab-content top-20">
{salesSelected &&                 <div role="tabpanel" className="tab-pane active" id="home">
                    <div className="row">
                        <div className="col-lg-6" style={{fontSize: '20px'}} >
                            <ul className="list-unstyled">

                                <li>Monthly Sales Report:<span> Yes </span>
                                </li>
                                    <hr />

                                <li>Sales per SKU:
                                </li>
                                <hr />

                                <li>Product & SKU 1:<span> 3 </span>
                                </li>
                                <hr className="hr-product"/>

                                <li>Product & SKU 2:<span> 5 </span>
                                </li>
                                    <hr className="hr-product"/>
                                    <li>Product & SKU 3:<span> 10 </span>
                                </li>
                                    <hr className="hr-product"/>
                                    <li>Product & SKU 4:<span> 20 </span>
                                </li>
                                    <hr className="hr-product"/>
                            </ul>
                        </div>
                        <div className="col-lg-6" style={{fontSize: '20px'}}>
                            <ul className="list-unstyled">

                                <li>
                                Missing Statement:
                                </li>
                                <li>
                                    Missing statement text will be here like a description. Missing statement text will be here like a description.
                                </li>
                                <hr />
                            </ul>
                        </div>
                    </div>
                </div>}
{shelfSelected &&                 
                <div  className="tab-pane active" id="shelf">
                    <div className="row">
                        <div className="col-lg-6" style={{fontSize: '20px'}}>
                            <ul className="list-unstyled">

                                <li>Availability:<span> Yes </span>
                                    {/* <i className="fa fa-check"></i> */}
                                </li>
                                <hr />

                                
                                <li>Visibility & VAPS:<span> Visibility Product </span></li>
                                <hr/>

                                <li>Facings:<span> 4 </span>
                                    {/* <i className="fa fa-check">Facings</i> */}
                                </li>
                                <hr/>

                                <li>Depths:<span> 5 </span>
                                    {/* <i className="fa fa-check">Facings</i> */}
                                </li>
                                <hr/>

                                <li>Correct Share:<span> Yes </span>
                                    {/* <i className="fa fa-check">Facings</i> */}
                                </li>
                                <hr/>


                            </ul>
                        </div>
                        <div className="col-lg-6" style={{fontSize: '20px'}}>
                            <ul className="list-unstyled">
                                <li>Price Shown:
                                    <span> € 2.35 </span>
                                        {/* <i className="fa fa-check"></i> */}
                                    </li>
                                    <hr/>

                                <li>Brand Communication:
                                <span> € 2.35 </span>
                                        {/* <i className="fa fa-check"></i> */}

                                </li>
                                <hr/>

                                <li>Minimum Price:
                                <span> € 4.50 </span>
                                    {/* <i className="fa fa-check"></i> */}
                                </li>
                                <hr/>

                                <li>Maximum Price:
                                <span> € 6.50 </span>
                                </li>
                                <hr/>


                            </ul>
                        </div>
                    </div>
                </div>
}

{secondarySelected &&  
                <div className="tab-pane active" id="secondary">
                    <div className="row">
                        <div className="col-lg-6" style={{fontSize: '20px'}}>
                            <ul className="list-unstyled">

                            <li>Availability:<span> Yes </span>
                                    {/* <i className="fa fa-check"></i> */}
                                </li>
                                <hr />

                                
                                <li>Visibility & VAPS:<span> Visibility Product </span></li>
                                <hr/>

                                <li>Facings:<span> 10 </span>
                                    {/* <i className="fa fa-check">Facings</i> */}
                                </li>
                                <hr/>

                                <li>Depths:<span> 6 </span>
                                    {/* <i className="fa fa-check">Facings</i> */}
                                </li>
                                <hr/>




                            </ul>
                        </div>
                        <div className="col-lg-6" style={{fontSize: '20px'}}>
                            <ul className="list-unstyled">

                            <li>Correct Share:<span> Yes </span>
                                    {/* <i className="fa fa-check">Facings</i> */}
                                </li>
                                <hr/>

                                <li>Price Shown:<span> € 0.50 </span>
                                    {/* <i className="fa fa-check"></i> */}
                                </li>
                                <hr/>

                                <li>Brand Communication:
                                <span> € 2.35 </span>
                                        {/* <i className="fa fa-check"></i> */}

                                </li>
                                <hr/>

                            </ul>
                        </div>
                    </div>
                </div>}

{stockSelected &&  
                <div  className="tab-pane active" id="gondola">
                    <div className="row">
                        <div className="col-lg-6" style={{fontSize: '20px'}}>
                            <ul className="list-unstyled">

                                <li>Weekly Stock Report:<span> Yes </span>
                                    <i className="fa fa-check"></i>
                                </li>
                                <hr/>
                                <li>Additional Information:</li>
                                <li> Text is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only 
                                    <i className="fa fa-check"></i>
                                </li>
                                <hr/>

                                <li>Out Of Stock:
                                    <div className="radio-buttons-container" >
                                        <div className="button-radio" >
                                            <input type="radio" />
                                            <label for="gondola-sku">JM 1.0L</label>
                                        </div>
                            
                                        <div className="button-radio">
                                            <input type="radio" />
                                            <label for="gondola-sku">JMCBC SKU</label>
                                        </div>

                                        <div className="button-radio">
                                            <input type="radio" />
                                            <label for="gondola-sku">Manifest SKU</label>
                                        </div>

                                        <div className="button-radio">
                                            <input type="radio" />
                                            <label for="gondola-sku">VAPs</label>
                                        </div>

                                    </div>

                                </li>
                                <hr/>

                                
                            </ul>
                        </div>
                        <div className="col-lg-6" style={{fontSize: '20px'}}>
                            <ul className="list-unstyled">

                                <li>Additional Description: </li>
                                <li>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable
                                </li>
                                <hr/>

                            </ul>
                        </div>
                    </div>
                </div>}

{storeSelected &&  
                <div  className="tab-pane active" id="cross">
                    <div className="row">
                        <div className="col-lg-6" style={{fontSize: '20px'}}>
                            <ul className="list-unstyled">

                                <li>Monthly Meeting:<span> Yes </span>
                                </li>
                                <hr/>

                                <li>Task Agenda:
                                </li>
                                <hr/>

                                <li>Arranged Point for Next Meeting 1
                                </li>
                                <hr className="hr-product"/>
                                <li>Arranged Point for Next Meeting 2
                                </li>
                                <hr className="hr-product"/>
                                <li>Arranged Point for Next Meeting 3
                                </li>
                                <hr className="hr-product"/>
                                
                            </ul>
                        </div>
                        <div className="col-lg-6" style={{fontSize: '20px'}}>
                            <ul className="list-unstyled">

                            <li>Meeting Length:<span> 60 minutes </span></li>
                                <hr/>

                            <li>Actions:</li>
                            <li>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.
                            </li>
                            <hr/>
                            <li>Next Meeting <span>2023-03-21 23:03:04</span>
                            </li>
                            <hr/>

                            </ul>
                        </div>
                    </div>
                </div>}

{supplementartSelected &&  
                <div className="tab-pane active" id="beverage">
                    <div className="row">
                        <div className="col-lg-6" style={{fontSize: '20px'}}>
                            <ul className="list-unstyled">
                                <li>Customer Feedback: <span>Yes</span>
                                </li>
                                <hr/>

                                <li>Key point 1 This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet
                                </li>
                                <hr className="hr-product"/>

                                <li>Key point 2 This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet
                                </li>
                                <hr className="hr-product"/>

                                <li>To do:</li> 
                                <li>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum
                                </li>
                                <hr/>

                            </ul>
                        </div>
                        <div className="col-lg-6" style={{fontSize: '20px'}}>
                            <ul className="list-unstyled">
                                <li>Category Performance:</li>
                                <li>Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

                                </li>
                                <hr/>

                                <li>Competitor Activity:</li>
                                <li> Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </li>
                                <hr/>

                            </ul>
                        </div>
                    </div>
                </div>}

{/* {supplementartSelected &&
                <div role="tabpanel" className="tab-pane" id="counter">
                    <div className="row">
                        <div className="col-lg-6" style={{fontSize: '20px'}}>
                            <ul className="list-unstyled">

                                <li>Availability:
                                    <i className="fa fa-check"></i>
                                </li>
                                <hr/>

                                <li>SKUs:
                                    <div className="radio-buttons-container" >
                                        <div className="button-radio" >
                                            <input type="radio" />
                                            <label for="counter-sku">0.02 L</label>
                                        </div>
                                    </div>

                                </li>
                                <hr/>

                                
                                <li>Facings:<span> 232 </span></li>
                                <hr/>

                                <li>Right Location:
                                    <i className="fa fa-check"></i>
                                </li>
                                <hr/>
                            </ul>
                        </div>
                        <div className="col-lg-6" style={{fontSize: '20px'}}>
                            <ul className="list-unstyled">

                                <li>Price Communication:
                                    <i className="fa fa-check"></i>
                                </li>
                                <hr/>

                                <li>Communication Tools:
                                    <i className="fa fa-check"></i>
                                </li>
                                <hr/>

                                <li>Promotion:
                                    <i className="fa fa-check"></i>
                                </li>
                                <hr/>

                                <li>Cooling Device:
                                    <i className="fa fa-check"></i>
                                </li>
                                <hr/>                            
                            </ul>
                        </div>
                    </div>
                </div>} */}


            </div>



            <div id="myCarousel" style={{display:'none'}} className="carousel slide" data-ride="carousel">


                <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev"><span
                        className="carousel-control-prev-icon" aria-hidden="true"></span><span
                        className="sr-only">Previous</span></a>

                <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next"><span
                        className="carousel-control-next-icon" aria-hidden="true"></span><span
                        className="sr-only">Next</span></a>
                <ol className="carousel-indicators">
                    <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                    <li data-target="#myCarousel" data-slide-to="1"></li>
                    <li data-target="#myCarousel" data-slide-to="2"></li>
                    <li data-target="#myCarousel" data-slide-to="3"></li>
                    <li data-target="#myCarousel" data-slide-to="4"></li>
                    <li data-target="#myCarousel" data-slide-to="5"></li>
                </ol>

                <div className="carousel-inner">
                    <div className="item active">

                        <h3 style={{textAlign: 'center', marginBottom: '35px', fontWeight: 900}}>General Info</h3>
                        <hr/>
                        <div className="row">
                            <div className="col-lg-6" style={{fontSize: '20px'}}>
                                <ul className="list-unstyled">
                                    <li>Discount: <span> + report.discount + %</span></li>
                                    <hr/>

                                    <li>Trained Supervisor:
                                        <i className="fa fa-check"></i>

                                    </li>
                                    <hr/>

                                    <li>Right Inventory:
                                        <i className="fa fa-check"></i>

                                    </li>
                                    <hr/>

                                    <li>Right Position:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>

                                    <li>Right Price:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>

                                    <li>Security Shelf:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>
                                    <li>Monthly Sales:<span> + report.monthlySales + </span></li>
                                        <hr/>
                                    <li>Warehouse Stock:<span> + report.warehousestock + </span></li>
                                        <hr/>
                                    <li>Inventory:<span> + report.inventory + </span></li>
                                        <hr/>
                                </ul>
                            </div>
                            <div className="col-lg-6" style={{fontSize: '20px'}}>
                                <ul className="list-unstyled">

                                    <li>Availability:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>

                                    <li>Shelf:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>

                                    <li>Cross:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>

                                    <li>Freezer:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>

                                    <li>Additional:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>

                                    <li>Checkout:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>
                                    <li>Facings:<span> report.facing </span></li>
                                        <hr/>
                                    <li>Depth:<span> report.depth </span></li>
                                        <hr/>
                                    <li>Comments:<span> report.comments </span></li>
                                        <hr/>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <h3 style={{textAlign: 'center', marginBottom: '35px', fontWeight: 900}}>Shelf</h3>
                        <hr/>
                        <div className="row">
                            <div className="col-lg-6" style={{fontSize: '20px'}}>
                                <ul className="list-unstyled">

                                    <li>Brand Communication:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>
                                    <li>Category Facings:<span> + report.podsShelf.categoryFacing + </span></li>
                                        <hr/>

                                    <li>Cold Communication:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>
                                    <li>Facings:<span> + report.podsShelf.facing + </span></li>
                                        <hr/>
                                    <li>Depth:<span> + report.podsShelf.depth + </span></li>
                                        <hr/>
                                    <li>High Traffic:<span> + report.podsShelf.hTraffic + </span></li>
                                        <hr/>
                                </ul>
                            </div>
                            <div className="col-lg-6" style={{fontSize: '20px'}}>
                                <ul className="list-unstyled">
                                    <li>Position:<span> + report.podsShelf.posicion.posicion + </span></li>
                                        <hr/>

                                    <li>Visible Price:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>

                                    <li>Related Products:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>

                                    <li>VAP: +
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>
                                    <li>VAP Type:<span> + report.podsShelf.tipoVap.tipoVap + </span></li>
                                        <hr/>
                                    <li>Visible:<span> + report.podsShelf.visible + </span></li>
                                        <hr/>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <h3 style={{textAlign: 'center', marginBottom: '35px', fontWeight: 900}}>Cross</h3>
                        <hr/>
                        <div className="row">
                            <div className="col-lg-6" style={{fontSize: '20px'}}>
                                <ul className="list-unstyled">

                                    <li>Brand Communication:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>
                                    <li>Facings:<span> + report.podsCross.facing + </span></li>
                                        <hr/>
                                    <li>Depth:<span> + report.podsCross.depth + </span></li>
                                        <hr/>
                                    <li>High Traffic:<span> + report.podsCross.hTraffic + </span></li>
                                        <hr/>
                                </ul>
                            </div>
                            <div className="col-lg-6" style={{fontSize: '20px'}}>
                                <ul className="list-unstyled">

                                    <li>Related Products:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>

                                    <li>VAP:
                                        <i className="fa fa-check"></i>

                                    </li>
                                    <hr/>
                                    <li>VAP Type:<span> + report.podsCross.tipoVap.tipoVap + </span></li>
                                        <hr/>
                                    <li>Visible:<span> + report.podsCross.visible + </span></li>
                                        <hr/>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <h3 style={{textAlign: 'center', marginBottom: '35px', fontWeight: 900}}>Freezer</h3>
                        <hr/>
                        <div className="row">
                            <div className="col-lg-6" style={{fontSize: '20px'}}>
                                <ul className="list-unstyled">

                                    <li>
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>

                                    <li>
                                        <i className="fa fa-close"></i>
                                    </li>
                                    <hr/>
                                    <li>Facings:<span> + report.podsFreezer.facing + </span></li>
                                        <hr/>
                                    <li>Depth:<span> + report.podsFreezer.depth + </span></li>
                                        <hr/>
                                    <li>Position:<span> + report.podsFreezer.posicion.posicion + </span></li>
                                        <hr/>
                                </ul>
                            </div>
                            <div className="col-lg-6" style={{fontSize: '20px'}}>
                                <ul className="list-unstyled">

                                    <li>Visible Price:
                                        <i className="fa fa-close"></i>
                                    </li>
                                    <hr/>

                                    <li>VAP:
                                        <i className="fa fa-check"></i>

                                    </li>
                                    <hr/>
                                    <li>VAP Type:<span> + report.podsFreezer.tipoVap.tipoVap + </span></li>
                                        <hr/>
                                    <li>Visible:<span> + report.podsFreezer.visible + </span></li>
                                        <hr/>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="item">

                        <h3 style={{textAlign: 'center', marginBottom: '35px', fontWeight: '900'}}>Additional</h3>
                        <hr/>
                        <div className="row">
                            <div className="col-lg-6" style={{fontSize: '20px'}}>
                                <ul className="list-unstyled">

                                    <li>Brand Communication:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>
                                    <li>Facings:<span> + report.podsAdditional.facing + </span></li>
                                        <hr/>
                                    <li>Depth:<span> + report.podsAdditional.depth + </span></li>
                                        <hr/>
                                    <li>High Traffic:<span> + report.podsAdditional.hTraffic + </span></li>
                                        <hr/>
                                </ul>
                            </div>
                            <div className="col-lg-6" style={{fontSize: '20px'}}>
                                <ul className="list-unstyled">

                                    <li>Related Products:
                                        <i className="fa fa-check"></i>

                                    </li>
                                    <hr/>

                                    <li>VAP:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>
                                    <li>VAP Type:<span> + report.podsAdditional.tipoVap.tipoVap + </span></li>
                                        <hr/>
                                    <li>Visible:<span> + report.podsAdditional.visible + </span></li>
                                        <hr/>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="item">
                        <h3 style={{textAlign: 'center', marginBottom: '35px', fontWeight: 900}}>Checkout</h3>
                        <hr/>
                        <div className="row">
                            <div className="col-lg-6" style={{fontSize: '20px'}}>
                                <ul className="list-unstyled">

                                    <li>Brand Communication:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>
                                    <li>Facings:<span> + report.podsCheckout.facing + </span></li>
                                        <hr/>
                                    <li>Depth:<span> + report.podsCheckout.depth + </span></li>
                                        <hr/>
                                    <li>Position:<span> + report.podsCheckout.posicion.posicion + </span></li>
                                        <hr/>
                                </ul>
                            </div>
                            <div className="col-lg-6" style={{fontSize: '20px'}}>
                                <ul className="list-unstyled">

                                    <li>Visible Price:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>

                                    <li>VAP:
                                        <i className="fa fa-check"></i>
                                    </li>
                                    <hr/>
                                    <li>VAP Type:<span> + report.podsCheckout.tipoVap.tipoVap + </span></li>
                                        <hr/>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className="panel panel-minimalistic">

                <div className="panel-heading" role="tab" id="headingImages" data-toggle="collapse"
                    href="#collapseImages" aria-expanded="true" aria-controls="collapseImages">
                    <h4 className="panel-title">Images</h4>
                </div>

                <div id="collapseImages" className="panel-collapse collapse in" role="tabpanel"
                    aria-labelledby="headingImages">
                    <div className="panel-body">
                        <div className="row">

                            <div className="col-lg-3">
                                <img src="https://static.javatpoint.com/computer/images/what-is-the-url.png" style={{width: '500px'}}/>
                            </div>
 
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    </div>
    </div>
    </React.Fragment>
    )

}

export default Modal;