import React from 'react'
import { Col, Row, List, Checkbox } from 'antd';
import botellaJagger from '../../resources/img/botellaJagger.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
// const { Panel } = Collapse
const onChange = (checkedValues) => {
    // console.log('checked = ', checkedValues);
  };


const StockList = ( props ) => {

    const outOfStockChecks = [];
    const outOfStockSelected = [];

    for( let outOfStock of props.detailVisit.stock_outtostockSKUs){
        outOfStockChecks.push(
            {
                label: outOfStock.sku, 
                value: outOfStock.sku
            }
            // outOfStock.sku
        )
        if( outOfStock.estatus !== 0){
            outOfStockSelected.push( outOfStock.sku );
        }
    }


    const elementsList = [
        {
            descripcion: 'Weekly Stock Report',
            dato: props.detailVisit.stock_weeklystock === 1 ? <FontAwesomeIcon icon={(faCircleCheck)} style={{color: "#36c953",}} size="xl"/> :<FontAwesomeIcon icon={faCircleXmark} style={{color: "#ce3b3b",}} size="xl"/>
            // dato: props.detailVisit.stock_weeklystock === 1 ? 'Yes':'No'
        },
        {
            title: 'Additional Information:',
            descripcion: props.detailVisit.stock_weekadditionalinf === "" ? "No Additional Information" : props.detailVisit.stock_weekadditionalinf,
        },
        {
            title: 'Out Of Stock',
            descripcion:<Checkbox.Group options={outOfStockChecks} value={ outOfStockSelected } onChange={onChange} />,
        },
        {
            title: 'Additional Description:',
            descripcion: props.detailVisit.stock_Aditionaldesc === "" ? "No Additional Description": props.detailVisit.stock_Aditionaldesc,
    
        },
    ]

    return (
        <div>
            <Row>
                <Col span={14}>
                    <List
                        size="small"
                        bordered
                        dataSource={elementsList}
                        renderItem={(item) =>
                            <List.Item>
                                <p>
                                    <strong>{item.title} </strong>
                                </p>
                                {item.descripcion} <span>{item.dato}</span>
                            </List.Item>}
                    />
                </Col>
                <Col span={10}>
                    <div className='imagenList' 
                    // style={{minHeight: '275px', maxHeight: '368px'}}
                    >
                        <img src={props.detailVisit.stock_Photos.length > 0 ? props.detailVisit.stock_Photos[0].base64 :botellaJagger} />
                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default StockList