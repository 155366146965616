import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Modal } from 'antd';
import { useContext, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import React from 'react'
import './Tables.css';
import TabsComponent from './TabsComponent';

import iconoDetail from '../../resources/img/iconoDetail.png';
import logoGTR from '../../resources/img/logoHorizontal.png';
import LogUserInformationContext from '../../../store/LogUser-context';



const TableComponent = ( props ) => {

    const [isSubmitting, setIsSubmitting ] = useState( false ); 
    const [modal2Open, setModal2Open] = useState(false);
    const userLogCtx  = useContext( LogUserInformationContext );
    const [ visitDetail, setVisitDetail ] = useState(
        {
        idreporte: 0,
        tarea: {
            idtarea: 0,
        }
        }
    );

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const onClickReportDetail = async ( idTask ) => {

        setIsSubmitting(true);
        const tokenKey =  localStorage.getItem('LogTokenAccess') ? localStorage.getItem('LogTokenAccess') : '';
        const response = await fetch(`${userLogCtx.domainUrlAPI}/controllers/visits/getReportsByIdTask?idtarea=${ idTask }`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': tokenKey
            }
        });

        const detailVisitObtained = await response.json();
        
        if ( detailVisitObtained.length > 0 ){
            setVisitDetail( detailVisitObtained[0] )
        }

        setIsSubmitting(false);

    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            title: 'Store',
            dataIndex: 'venue',
            key: 'venue',
            // width: '30%',
            ...getColumnSearchProps('venue'),
            sorter: (a, b) => a.venue.length - b.venue.length,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Status Store',
            dataIndex: 'status',
            key: 'status',
            ...getColumnSearchProps('status'),
            
        },
        {
            title: 'B.A',
            dataIndex: 'ba',
            key: 'ba',
            ...getColumnSearchProps('ba'),
        },
        {
            title: 'Previous Visit',
            dataIndex: 'previous',
            key: 'previous',
            ...getColumnSearchProps('previous'),
      
        },
        {
            title: 'Visit',
            dataIndex: 'visit',
            key: 'visit',
            ...getColumnSearchProps('visit'),
        },
        {
            title: 'Action',
            className: 'center',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <img onClick={() => {
                        setModal2Open(true);
                        // console.log( "id Task is ",record.key ); 
                        onClickReportDetail( record.key );
                        }} 
                    className='iconoDetail' src={iconoDetail} alt="Icono GTR" />
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="tablaVenue">
                <Table  scroll={{ x: 1000, }} columns={columns} dataSource={props.tableClientsRows} />
            </div>

            <Modal
                title="Store Detail"
                centered
                open={modal2Open}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}
            >

                { isSubmitting ?
                <div className='spinnerloading'>

                </div> 
                // <h3> Loading ... </h3>
                :
                <>
                <div className='headModal'>
                    <img src={logoGTR} />
                    <span className='blockSpan'>
                        <strong>Store: </strong>
                        {visitDetail.tarea.idtarea !== 0 ? visitDetail.tarea.cliente.nombre : ''}
                    </span>
                    <span className='blockSpan'>
                        <strong>Date: </strong>
                        {visitDetail.tarea.idtarea !== 0 ? visitDetail.fecharegistro : ''}
                    </span>
                </div>
                <div>
                    <TabsComponent visitDetailProp={visitDetail}></TabsComponent>
                </div>

                </>
                  
                
                }
            </Modal>

        </div>

    );

};

export default TableComponent