import React from 'react'
import { Col, Row, List } from 'antd';
import botellaJagger from '../../resources/img/botellaJagger.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
// const { Panel } = Collapse



const SalesTarget = ( props ) => {

    const listElements = [];
    
    listElements.push({
        descripcion: 'Monthly Sales Report',
        dato: props.detailVisit.salesTrgt_month_sales_report === 1 ? <FontAwesomeIcon icon={(faCircleCheck)} style={{color: "#36c953",}} size="xl"/> :<FontAwesomeIcon icon={faCircleXmark} style={{color: "#ce3b3b",}} size="xl"/>
        // dato: props.detailVisit.salesTrgt_month_sales_report === 1 ? 'Yes':'No'
    })

    listElements.push({
        title: 'Sales per SKU:',
        dato: ''
    })

    for( let skuSales of props.detailVisit.salesTrgt_skus ){
        listElements.push(
            {
            descripcion: `${skuSales.producto.tipoproducto} ${skuSales.sku}`,
            dato: skuSales.soldUnits
            }
        )
    }

    listElements.push({
        title: 'Missing Statement:',
        descripcion: props.detailVisit.salesTrgt_statement !== ""? props.detailVisit.salesTrgt_statement: 'No content'      
    });



    return (
        <div>
            <Row>
                <Col span={14}>
                    <List
                        size="small"
                        className='listSku'
                        bordered
                        dataSource={listElements}
                        renderItem={(item) => <List.Item>
                            <p>
                                <strong>{item.title} </strong>
                            </p>
                            {item.descripcion} <span>{item.dato}</span>
                        </List.Item>}
                    />
                </Col>
                <Col span={10}>
                    <div className='imagenList'>
                        <img src={botellaJagger} />
                    </div>
                </Col>
            </Row>

        </div>
    )
}

export default SalesTarget