import { useContext } from "react";
import Login from "../components/Login/Login";
import { useNavigate } from "react-router-dom";
import LogUserInformationContext from "../store/LogUser-context";
import LogUserProvider from "../store/LogUserProvider";

const LoginPage = props => {

    const userLogCtx  = useContext( LogUserInformationContext );

    const navigate = useNavigate();


    const loginHandler = async ( formUser ) =>{

        const loginResponse = await fetch(`${userLogCtx.domainUrlAPI}/controllers/user/loginWebUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body:JSON.stringify( {
                usuario: formUser.username,
                password: formUser.password
            })

        });

        const userInformation = await loginResponse.json();

        if( userInformation.idUsuario === 0 ){
            
            return {
                authenticationResponse: 0
            }
        }
        else{
            userLogCtx.addLogUserInformation( userInformation );
            localStorage.setItem('LogTokenAccess', userInformation.token);
            localStorage.setItem('xRcAsRqweLtaAsewq', userInformation.idUsuario);
            localStorage.setItem('qiewCWhWdajda', userInformation.idRol);
            localStorage.setItem('pqiCBwhYjsa', userInformation.pais.region.idregion);
            localStorage.setItem('urOxJNAhiQSro', userInformation.pais.idpais);
            navigate('dashboard');
            return {
                authenticationResponse: 1
            }
        }

        

    }
  
    // const logoutHandler = () =>{

    // }

    return (
        <LogUserProvider>
            <Login onLoginHandler = {loginHandler}/> 
        </LogUserProvider>
    )
}

export default LoginPage;   