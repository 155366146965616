import React from "react";

const LogUserInformationContext = React.createContext({
    domainUrlAPI: 'http://localhost:8000/',
    userLogInformation: null,
    addLogUserInformation:  ( logUserInformation ) => {},
    clearUserInformation:  () => {},

})

export default LogUserInformationContext;